<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :dataSource="tableData"
        :columns="tableColumns"
        :loading="isLoading"
        :pagination="{ defaultPageSize: 10 }"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper as TableStyleWrapper } from './style';
import { TableWrapper } from '../../styled';
import { computed } from 'vue';
import { useStore } from 'vuex';
// import { DataService } from '@/config/dataService/dataService';

const tableColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '90px',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Client Name',
    dataIndex: 'client_name',
    key: 'client_name',
  },
  // {
  //   title: 'Payment Provider',
  //   dataIndex: 'payment_provider',
  //   key: 'payment_provider',
  // },
  // {
  //   title: 'Website URL',
  //   dataIndex: 'vsf_url',
  //   key: 'vsf_url',
  // },
  // {
  //   title: 'ES Host',
  //   dataIndex: 'es_url',
  //   key: 'es_url',
  // },
  // {
  //   title: 'ES Index',
  //   dataIndex: 'es_index',
  //   key: 'es_url',
  // },
  {
    title: 'Store ID',
    dataIndex: 'store_id',
    key: 'store_id',
  },
  {
    title: 'Website ID',
    dataIndex: 'website_id',
    key: 'website_id',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];
const StoreViewsTable = {
  name: 'StoreViewsTable',
  components: { TableStyleWrapper, TableWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const isLoading = computed(() => state.storeviews.loading);
    const storeviews = computed(() => state.storeviews.storeviews);
    const allClients = computed(() => state.clients.clients);
    const filterData = computed(() => state.storeviews.filterData);

    const onEdit = (storeview) => {
      commit('setStoreview', storeview);
      commit('toggleStoreviewEditModal', true);
    };

    const tableData = computed(() =>
      storeviews.value
        .filter((storeview) => {
          if (!filterData.value.name) return true;
          const { company_id } = storeview;
          const client_name = company_id ? allClients.value.find((client) => +client.id === +company_id)['name'] : '';
          return client_name.toLowerCase().includes(filterData.value.name.toLowerCase());
        })
        .map((storeview) => {
          const { id, code, store_id, company_id, website_id } = storeview;

          return {
            key: id,
            id,
            code,
            store_id,
            client_name: company_id ? allClients.value.find((client) => +client.id === +company_id)['name'] : '',
            // payment_provider,
            // vsf_url,
            // es_url,
            // es_index,
            website_id,
            action: (
              <div class="table-actions">
                <>
                  <sdButton class="btn-icon" type="default" shape="circle" onClick={() => onEdit(storeview)}>
                    <sdFeatherIcons type="edit" size="16" />
                  </sdButton>
                  <a-popconfirm title="Sure to delete?" onConfirm={() => dispatch('deleteStoreview', { id })}>
                    <sdButton class="btn-icon" type="default" shape="circle">
                      <sdFeatherIcons type="trash" size="16" />
                    </sdButton>
                  </a-popconfirm>
                </>
              </div>
            ),
          };
        }),
    );

    return {
      tableColumns,
      tableData,
      isLoading,
      onEdit,
    };
  },
};

export default StoreViewsTable;
</script>
