<template>
  <CardToolbox>
    <sdPageHeader title="All StoreViews">
      <template #buttons>
        <sdButton class="btn-add_new" size="default" type="primary" key="1" @click="addNew">
          + Add New Storeview
        </sdButton>
      </template>
    </sdPageHeader>
  </CardToolbox>

  <div style="margin-left: 40px">
    <a-col :lg="6" :sm="12" :xs="24">
      <a-form-item label="Client Name">
        <a-input v-model:value="filter.name" @keyup.enter.prevent="applyFilters" />
      </a-form-item>
    </a-col>
  </div>

  <Main>
    <a-row :gutter="15">
      <a-col :xs="24">
        <StoreViewsTable />
      </a-col>
    </a-row>
  </Main>
  <a-modal v-model:visible="visibleEditModal" :title="modalTitle" @ok="onSave(form, form)" width="50%">
    <BasicFormWrapper>
      <a-form name="storeview" layout="horizontal">
        <a-row :gutter="30">
          <a-col :md="6" :sm="12" style="backgroundcolor: #40a9ff">
            <a-button block class="mt-25 mb-25" @click="setStep1(0)">General</a-button>
            <a-button block class="mb-25" @click="setStep1(2)">Elastic</a-button>
            <a-button block class="mb-25" @click="setStep1(3)">Email</a-button>
            <a-button block class="mb-25" @click="setStep1(5)">Payment Provider</a-button>
          </a-col>
          <a-col :md="18" :sm="12">
            <a-row :gutter="30" align="middle" v-show="step === 0">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Code">
                  <a-input class="small" v-model:value="form.code" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Client Name">
                  <a-select v-model:value="form.company_id" style="width: 100%">
                    <a-select-option value="">please select</a-select-option>
                    <a-select-option v-for="client in clients" :key="client.id" :value="client.id">{{
                      client.name
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 0">
              <a-col :md="8" :xs="24" class="mb-25">
                <a-form-item label="Language">
                  <a-input class="small" v-model:value="form.language" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :xs="24" class="mb-25">
                <a-form-item label="Currency">
                  <a-input class="small" v-model:value="form.currency" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :xs="24" class="mb-25">
                <a-form-item label="Currency Code">
                  <a-input class="small" v-model:value="form.currency_code" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 0">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Store ID">
                  <a-input type="number" class="small" v-model:value="form.store_id" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Website ID">
                  <a-input type="number" class="small" v-model:value="form.website_id" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 0">
              <a-col :md="18" :xs="24" class="mb-25">
                <a-form-item label="Website URL">
                  <a-input class="small" v-model:value="form.vsf_url" placeholder="http://localhost:3000" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :xs="24" class="mb-25">
                <a-form-item label="Preview Key">
                  <a-input class="small" v-model:value="form.vsf_preview" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 0">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Webhook Token">
                  <a-input class="small" v-model:value="form.webhook_token" placeholder="http://localhost:3000" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 0">
              <a-col :md="12" :xs="24" class="sandbox" :style="{ marginTop: '15px' }">
                <a-switch class="mr-10" v-model:checked="form.shipment_with_label" /><span
                  >Create Shipment With Label</span
                >
              </a-col>
              <a-col :md="12" :xs="24" class="sandbox" :style="{ marginTop: '15px' }">
                <a-switch class="mr-10" v-model:checked="form.shipment_without_label" /><span
                  >Create Shipment Without Label</span
                >
              </a-col>
              <a-col :md="12" :xs="24" class="sandbox" :style="{ marginTop: '15px' }">
                <a-switch class="mr-10" v-model:checked="form.shipment_without_tracking" /><span
                  >Create Shipment Without Tracking or Label</span
                >
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 0">
              <a-col :md="12" :xs="24" class="mb-25" :style="{ marginTop: '15px' }">
                <a-radio-group v-model:value="form.default_provider">
                  <a-radio-button value="paypal">Paypal</a-radio-button>
                  <a-radio-button value="stripe">Stripe</a-radio-button>
                  <a-radio-button value="cybersource">Cybersource</a-radio-button>
                  <a-radio-button value="checkoutcom">Checkout.Com</a-radio-button>
                </a-radio-group>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 5">
              <a-collapse :activeKey="activeKey" :expand-icon-position="expandIconPosition">
                <a-collapse-panel key="1" header="Cybersource">
                  <a-row class="ant-row-middle" :gutter="5">
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Merchant Key">
                        <a-input v-model:value="form.cybersource.merchant_id" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="API Key">
                        <a-input v-model:value="form.cybersource.key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Shared Secret">
                        <a-input v-model:value="form.cybersource.shared_secret_key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch
                        class="mr-10"
                        v-model:checked="form.cybersource.sandbox"
                        :style="{ marginTop: '15px' }"
                      /><span>Sandbox mode?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox" :style="{ marginTop: '15px' }">
                      <a-switch class="mr-10 mt-10" v-model:checked="form.cybersource.no_capture" /><span
                        >No Capture?</span
                      >
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox" :style="{ marginTop: '15px' }">
                      <a-switch class="mr-10 mt-10" v-model:checked="form.cybersource.manual_capture" /><span
                        >Manual Capture?</span
                      >
                    </a-col>
                  </a-row>
                  <template #extra>
                    <a-switch class="mr-10" v-model:checked="form.cybersource.status" /><span>Enabled</span>
                  </template>
                </a-collapse-panel>
                <a-collapse-panel key="2" header="PayPal" :disabled="false">
                  <a-row class="ant-row-middle" :gutter="5">
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Client ID">
                        <a-input v-model:value="form.paypal.client_id" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Client Secret">
                        <a-input v-model:value="form.paypal.client_secret" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Public Key">
                        <a-input v-model:value="form.paypal.public_key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch class="mr-10" v-model:checked="form.paypal.sandbox" /><span>Sandbox mode?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch
                        class="mr-10 mt-10"
                        v-model:checked="form.paypal.no_capture"
                        :style="{ marginTop: '15px' }"
                      /><span>No Capture?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox" :style="{ marginTop: '15px' }">
                      <a-switch class="mr-10 mt-10" v-model:checked="form.paypal.manual_capture" /><span
                        >Manual Capture?</span
                      >
                    </a-col>
                  </a-row>
                  <template #extra>
                    <a-switch class="mr-10" v-model:checked="form.paypal.status" /><span>Enabled</span>
                  </template>
                </a-collapse-panel>
                <a-collapse-panel key="3" header="Stripe">
                  <a-row class="ant-row-middle" :gutter="5">
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Public Key">
                        <a-input v-model:value="form.stripe.public_api_key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Secret Key">
                        <a-input v-model:value="form.stripe.secret_api_key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Webhook Secret">
                        <a-input v-model:value="form.stripe.webhook_secret" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch class="mr-10" v-model:checked="form.stripe.sandbox" :style="{ marginTop: '15px' }" />
                      <span>Sandbox mode?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch
                        class="mr-10 mt-10"
                        v-model:checked="form.stripe.no_capture"
                        :style="{ marginTop: '15px' }"
                      /><span>No Capture?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch class="mr-10 mt-10" v-model:checked="form.stripe.manual_capture" /><span
                        >Manual Capture?</span
                      >
                    </a-col>
                  </a-row>
                  <template #extra>
                    <a-switch class="mr-10" v-model:checked="form.stripe.status" /><span>Enabled</span>
                  </template>
                </a-collapse-panel>
                <a-collapse-panel key="4" header="Checkout.com">
                  <a-row class="ant-row-middle" :gutter="5">
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Public API Key">
                        <a-input v-model:value="form.checkoutcom.public_api_key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Secret API Key">
                        <a-input v-model:value="form.checkoutcom.secret_api_key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Webhook Secret">
                        <a-input v-model:value="form.checkoutcom.webhook_secret" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch
                        class="mr-10"
                        v-model:checked="form.checkoutcom.sandbox"
                        :style="{ marginTop: '15px' }"
                      /><span>No Capture?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch
                        class="mr-10 mt-10"
                        v-model:checked="form.checkoutcom.no_capture"
                        :style="{ marginTop: '15px' }"
                      /><span>Manual Capture?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch class="mr-10 mt-10" v-model:checked="form.checkoutcom.manual_capture" /><span
                        >Sandbox mode?</span
                      >
                    </a-col>
                  </a-row>
                  <template #extra>
                    <a-switch class="mr-10" v-model:checked="form.checkoutcom.status" /><span>Enabled</span>
                  </template>
                </a-collapse-panel>
                <a-collapse-panel key="5" header="Checkout.com2">
                  <a-row class="ant-row-middle" :gutter="5">
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Public API Key">
                        <a-input v-model:value="form.checkoutcom2.public_api_key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Secret API Key">
                        <a-input v-model:value="form.checkoutcom2.secret_api_key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Webhook Secret">
                        <a-input v-model:value="form.checkoutcom2.webhook_secret" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch
                        class="mr-10"
                        v-model:checked="form.checkoutcom2.sandbox"
                        :style="{ marginTop: '15px' }"
                      /><span>No Capture?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch
                        class="mr-10 mt-10"
                        v-model:checked="form.checkoutcom2.no_capture"
                        :style="{ marginTop: '15px' }"
                      /><span>Manual Capture?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch class="mr-10 mt-10" v-model:checked="form.checkoutcom2.manual_capture" /><span
                        >Sandbox mode?</span
                      >
                    </a-col>
                  </a-row>
                  <template #extra>
                    <a-switch class="mr-10" v-model:checked="form.checkoutcom2.status" /><span>Enabled</span>
                  </template>
                </a-collapse-panel>
                <a-collapse-panel key="5" header="Barclay Enterprise">
                  <a-row class="ant-row-middle" :gutter="5">
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Client ID">
                        <a-input v-model:value="form.barclaysenterprise.client_id" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Secret API Key">
                        <a-input v-model:value="form.barclaysenterprise.secret_key" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24">
                      <a-form-item label="Enterprise">
                        <a-input v-model:value="form.barclaysenterprise.enterprise" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch
                        class="mr-10"
                        v-model:checked="form.barclaysenterprise.sandbox"
                        :style="{ marginTop: '15px' }"
                      /><span>No Capture?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch
                        class="mr-10 mt-10"
                        v-model:checked="form.barclaysenterprise.no_capture"
                        :style="{ marginTop: '15px' }"
                      /><span>Manual Capture?</span>
                    </a-col>
                    <a-col :md="12" :xs="24" class="sandbox">
                      <a-switch class="mr-10 mt-10" v-model:checked="form.barclaysenterprise.manual_capture" />
                      <span>Sandbox mode?</span>
                    </a-col>
                  </a-row>
                  <template #extra>
                    <a-switch class="mr-10" v-model:checked="form.barclaysenterprise.status" /><span>Enabled</span>
                  </template>
                </a-collapse-panel>
              </a-collapse>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 15">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="Payment Provider">
                  <a-select v-model:value="form.payment_provider" style="width: 100%">
                    <a-select-option value="">please select</a-select-option>
                    <a-select-option value="paypal">paypal</a-select-option>
                    <a-select-option value="stripe">stripe</a-select-option>
                    <a-select-option value="barclaycard">barclaycard</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 15">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="API key 1">
                  <a-input class="small" v-model:value="form.api_key_1" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="API key 2">
                  <a-input class="small" v-model:value="form.api_key_2" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 15">
              <a-col :lg="8" :md="12" :xs="24" class="mb-25">
                <a-form-item label="Payment Additional 1">
                  <a-input class="small" v-model:value="form.payment_additional_1" />
                </a-form-item>
              </a-col>
              <a-col :lg="8" :md="12" :xs="24" class="mb-25">
                <a-form-item label="Payment Additional 2">
                  <a-input class="small" v-model:value="form.payment_additional_2" />
                </a-form-item>
              </a-col>
              <a-col :lg="8" :md="12" :xs="24" class="mb-25">
                <a-form-item label="Payment Additional 3">
                  <a-input class="small" v-model:value="form.payment_additional_3" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 2">
              <a-col :md="18" :xs="24" class="mb-25">
                <a-form-item label="ElasticSearch Host">
                  <a-input class="small" v-model:value="form.es_url" placeholder="my-deployment.elastic-cloud.com" />
                </a-form-item>
              </a-col>
              <a-col :md="6" :xs="24" class="mb-25">
                <a-form-item label="ElasticSearch Index">
                  <a-input class="small" v-model:value="form.es_index" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 2">
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="ElasticSearch Username">
                  <a-input class="small" v-model:value="form.es_username" />
                </a-form-item>
              </a-col>
              <a-col :md="12" :xs="24" class="mb-25">
                <a-form-item label="ElasticSearch Password">
                  <a-input class="small" v-model:value="form.es_password" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="30" align="middle" v-show="step === 3">
              <a-col :md="8" :xs="24" class="mb-25">
                <a-form-item label="Email Domain">
                  <a-input class="small" v-model:value="form.email_domain" placeholder="omninext.com" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :xs="24" class="mb-25">
                <a-form-item label="Email Password">
                  <a-input class="small" type="password" v-model:value="form.email_password" />
                </a-form-item>
              </a-col>
              <a-col :md="8" :xs="24" class="mb-25">
                <a-form-item label="Email Sender">
                  <a-input class="small" v-model:value="form.email_sender" placeholder="webdev@omninext.com" />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </BasicFormWrapper>
  </a-modal>
</template>
<script>
import StoreViewsTable from './Table';
import { onMounted, computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { Main, CardToolbox } from '../../styled';
import { BasicFormWrapper } from '@/view/styled';
import { DataService } from '@/config/dataService/dataService';

const StoreviewsTableList = {
  name: 'StoreviewsTableList',
  components: { Main, CardToolbox, StoreViewsTable, BasicFormWrapper },
  setup() {
    const { state, dispatch, commit } = useStore();
    const filterData = computed(() => state.storeviews.filterData);
    const filter = ref(filterData);
    const visibleEditModal = computed({
      get() {
        return state.storeviews.visibleEditModal;
      },
      set(value) {
        commit('toggleStoreviewEditModal', value);
      },
    });
    const storeview = computed(() => {
      let storeview = state.storeviews.storeview;
      if (!storeview?.cybersource) {
        storeview.cybersource = {
          merchant_id: '',
          key: '',
          shared_secret_key: '',
          merchant_id_sandbox: '',
          key_sandbox: '',
          shared_secret_key_sandbox: '',
          status: false,
          manual_capture: false,
          refund_in_platform: false,
          sandbox: false,
          no_capture: false,
        };
      }
      if (!storeview?.paypal) {
        storeview.paypal = {
          id: -1,
          client_id: '',
          client_secret: '',
          public_key: '',
          client_id_sandbox: '',
          client_secret_sandbox: '',
          public_key_sandbox: '',
          status: false,
          manual_capture: false,
          refund_in_platform: false,
          sandbox: false,
          no_capture: false,
        };
      }
      if (!storeview?.stripe) {
        storeview.stripe = {
          public_api_key: '',
          secret_api_key: '',
          webhook_secret: '',
          public_api_key_sandbox: '',
          secret_api_key_sandbox: '',
          webhook_secret_sandbox: '',
          status: false,
          manual_capture: false,
          refund_in_platform: false,
          sandbox: false,
          no_capture: false,
        };
      }
      if (!storeview.checkoutcom) {
        storeview.checkoutcom = {
          public_api_key: '',
          secret_api_key: '',
          webhook_secret: '',
          public_api_key_sandbox: '',
          secret_api_key_sandbox: '',
          webhook_secret_sandbox: '',
          status: false,
          manual_capture: false,
          refund_in_platform: false,
          sandbox: false,
          no_capture: false,
        };
      }
      if (!storeview.checkoutcom2) {
        storeview.checkoutcom2 = {
          client_id: '',
          secret: '',
          enterprise: '',
          client_id_sandbox: '',
          secret_sandbox: '',
          enterprise_sandbox: '',
          status: false,
          manual_capture: false,
          refund_in_platform: false,
          sandbox: false,
          no_capture: false,
        };
      }

      if (!storeview.barclaysenterprise) {
        storeview.barclaysenterprise = {
          client_id: '',
          secret_key: '',
          enterprise: '',
          client_id_sandbox: '',
          secret_key_sandbox: '',
          enterprise_sandbox: '',
          status: false,
          manual_capture: false,
          no_capture: false,
          refund_in_platform: false,
          sandbox: false,
        };
      }

      return storeview;
    });
    const clients = computed(() => state.clients.clients);
    const activeKey = computed(() => {
      let result = [];
      let storeview = state.storeviews.storeview;
      if (storeview?.cybersource?.status) result.push('1');
      if (storeview?.paypal?.status) result.push('2');
      if (storeview?.stripe?.status) result.push('3');
      if (storeview?.checkoutcom?.status) result.push('4');
      if (storeview?.checkoutcom2?.status) result.push('5');
      console.log(result, 'active key');
      return result;
    });

    const form = reactive(storeview);
    const step = ref(0);
    const attributesData = ref({});

    const modalTitle = computed(() => {
      if (storeview.value.id > 0) {
        return `Update storeview`;
      } else {
        return `Register a new storeview`;
      }
    });

    const onSave = async (form) => {
      commit('setStoreview', form);
      commit('toggleStoreviewEditModal', false);
      await dispatch('updateStoreview');
    };

    const addNew = async () => {
      await commit('initStoreViews');
      await commit('toggleStoreviewEditModal', true);
    };

    const setStep1 = (value) => {
      step.value = value;
    };

    const getAllAttributes = async () => {
      const {
        data: { data, status: resStatus },
      } = await DataService.get(`${storeview.value.code}/getAllAttributes/attribute-sets/4/attributes`);

      if (resStatus === 'success') {
        let selectorData = data.filter((d) => d.frontend_input === 'select');
        attributesData.value = { main: data, selectorData };
      }

      attributesData.value = { main: [], selectorData: [] };
    };

    const clickCollapse = (key, value) => {
      console.log(activeKey, 'activeKey');
      if (value) {
        activeKey.value = activeKey.value.filter((val) => val !== key);
      } else {
        activeKey.value.push(key);
      }
      console.log(activeKey, 'activeKey');
    };

    watch(activeKey, (val) => {
      const keys = ['cybersource', 'paypal', 'stripe', 'checkoutcom'];
      keys.map((value, index) => {
        const key = (index + 1).toString();
        if (val.includes(key)) form.value[value].status = true;
        else form.value[value].status = false;
      });
    });

    onMounted(() => {
      dispatch('fetchClients');
      dispatch('fetchStoreviews');
      // getAllAttributes();
    });

    const applyFilters = async () => {
      commit('setClientName', filter.value.name);
    };

    return {
      applyFilters,
      filter,
      visibleEditModal,
      form,
      onSave,
      addNew,
      modalTitle,
      clients,
      step,
      setStep1,
      getAllAttributes,
      attributesData,
      activeKey,
      clickCollapse,
    };
  },
};

export default StoreviewsTableList;
</script>
<style lang="scss" scoped>
.ant-collapse {
  width: 100%;
  margin: 0 15px;
}

.ant-switch {
  margin-right: 10px;
}

.sandbox {
  padding: 0 20px !important;

  .ant-switch {
    // background-color: black;
  }
}
</style>
